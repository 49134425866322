import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const SelectLanguageWrapper = styled.div`
    font-weight: bold;
    position: relative;
    @media (max-width: ${theme`screens.md`}) {
        display: none;
    }
`;

export const LanguageTooltip = styled.div<{ open: boolean }>`
    ${tw`z-40 shadow-md px-4`};
    display: flex;
    background: white;
    transition: 0.14s ease-in-out;
    position: absolute;
    top: 35px;
    left: 0px;
    flex-direction: column;
    align-items: center;
    height: ${(props) => (props.open ? '50px' : '0')};
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    justify-content: flex-start;
`;

export const Button = styled.button<{ open: boolean }>`
    color: ${theme`colors.black`};
    cursor: pointer;
    background: transparent;
    border: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    svg:nth-of-type(1) {
        height: 10px;
        width: 20px;
    }
    span {
        width: 38px;
    }
    &:focus {
        outline: none;
    }
`;
