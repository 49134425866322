import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const MenuDesktopContainer = styled.div`
    ${tw`flex justify-between items-center w-full`}
    @media (max-width: ${theme`screens.md`}) {
        display: none;
    }
`;

export const MenuItemsContainer = styled.div`
    ${tw`flex inline ml-auto mr-12`}
`;
