import styled from 'styled-components';
import { MenuProps } from '../Header.types';
import { theme } from 'twin.macro';

export const MenuToggle = styled.div<MenuProps>`
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
    cursor: pointer;
    span {
        display: block;
        cursor: pointer;
        width: 25px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: ${theme`colors.black`};
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

        &:first-child {
            transform: ${({ open }) =>
                open ? 'translate(3px, 13px) rotate(-45deg)' : ''};
        }
        &:nth-child(2) {
            opacity: ${({ open }) => (open ? 0 : 1)};
        }
        &:nth-child(3) {
            transform: ${({ open }) =>
                open ? 'translate(5px, -13px) rotate(45deg)' : ''};
        }
        @media (min-width: ${theme`screens.md`}) {
            display: none;
        }
    }
`;
