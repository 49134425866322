import { MenuItem } from '@/layouts/Layout.types';
import { useLocation } from '@reach/router';

export const menuItems = (t, location): MenuItem[] => {
    if (
        location.pathname.includes('for-hospitals') ||
        location.pathname.includes('for-healthcare-providers')
    ) {
        const prefix = location.pathname.includes('for-hospitals')
            ? 'for-hospitals'
            : 'for-healthcare-providers';
        return [
            {
                name: t('navigation.problem'),
                link: `/${prefix}#problem`,
            },
            {
                name: t('navigation.solution'),
                link: `/${prefix}#solution`,
            },
            {
                name: t('navigation.aboutUs'),
                link: `/${prefix}#aboutUs`,
            },
            {
                name: t('navigation.contact'),
                link: `/${prefix}#contact`,
            },
        ];
    } else if (location.pathname.includes('for-technology-partners')) {
        const prefix = '/for-technology-partners';
        return [
            {
                name: t('navigation.aboutUs'),
                link: `${prefix}#aboutUs`,
            },
            {
                name: t('navigation.contact'),
                link: `${prefix}#contact`,
            },
        ];
    } else {
        return [];
    }
};
