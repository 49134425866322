import i18n from 'i18next';
import de from './de.json';
import en from './en.json';
import pl from './pl.json';

i18n.init({
    resources: {
        en: en,
        de: de,
        pl: pl,
    },
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: false,
    },
});

export default i18n;
