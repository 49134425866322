import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const Header = styled.header<{ isStickyNav: boolean }>((props) => [
    tw`bg-white ease-in-out duration-300 fixed w-full flex items-center justify-start  top-0 z-20`,
    `height: 80px;`,
    props.isStickyNav && tw`shadow-md`,
]);

export const NavbarContentContainer = styled.div`
    ${tw`max-w-screen-xl w-screen mx-auto flex items-center relative`};

    padding: 0 100px;

    @media (max-width: ${theme`screens.lg`}) {
        padding: 0 20px;
    }
`;
export const Logo = styled.img`
    ${tw`transform scale-75 -translate-x-7 md:scale-100 md:translate-x-0 md:scale-100 cursor-pointer`};
    height: 32px;
    min-height: 32px;
`;
