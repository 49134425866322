import React, { ReactNode, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import i18n from 'i18next';
import GlobalStyle from '@/global-style';
import SEO from '@/components/seo';
import * as Styled from './Layout.styled';
import { Header } from './Header';
import { Footer } from './Footer';
import { CookieBanner } from './CookieBanner';
import { navigate } from 'gatsby';
import { getRedirectLanguage } from '@/helpers';
import { useStickyNav } from './Layout.hook';
interface LayoutProperties {
    path: string;
    children: ReactNode;
}

const Layout = ({ path, children }: LayoutProperties): JSX.Element => {
    const { isScrolled, isStickyNav } = useStickyNav();
    useEffect(() => {
        if (path === '/') {
            const urlLang = getRedirectLanguage();
            navigate(`/${urlLang}`);
        }
    }, [path]);

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <>
            <SEO title={data.site.siteMetadata.title} lang={i18n.language} />
            <GlobalStyle />
            <Styled.MainContainer>
                <Header isStickyNav={isStickyNav} />
                <Styled.ContentContainer>{children}</Styled.ContentContainer>
                <Footer />
            </Styled.MainContainer>
        </>
    );
};

export default Layout;
