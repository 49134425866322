import '../Klaro/klaro.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './Footer.styled';
import { LocaleLink } from '@/components/LocaleLink';
import LogoSVG from 'static/images/LogoWhite.svg';
import { menuItems } from './Footer.helpers';
import {
    ParagraphWhite,
    ParagraphWhiteSemiBold,
} from '@/components/shared/Typography';
import * as klaro from '../Klaro/klaro';
import { klaroConfig } from '../Klaro/config.js';

export const Footer = () => {
    const { t, i18n } = useTranslation();

    if (typeof window !== 'undefined') {
        window.onload = async function () {
            window.klaro = klaro;
            window.klaroConfig = klaroConfig;
            klaro.setup(klaroConfig);
            klaro.defaultConfig.lang = i18n.language;
            var dc = document.cookie;
            if (dc == '') {
                klaro.show(klaroConfig, false);
            }
        };
    }

    const handleOnClick = async (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        klaro.setup(klaroConfig);
        klaro.defaultConfig.lang = i18n.language;
        await klaro.show(klaroConfig, false);
    };
    const styledAnchor = {
        color: 'white',

        paddingTop: '22px',
        // paddingRight: '24px',
        lineHeight: '48px',
    };
    return (
        <Styled.Footer>
            <Styled.FooterContent>
                <Styled.LeftContent>
                    <div>
                        <Styled.WhiteDot />
                    </div>
                    <div>
                        <ParagraphWhiteSemiBold>
                            {t('footer.contact')}
                        </ParagraphWhiteSemiBold>
                        <ParagraphWhite>
                            <Styled.ExternalLink
                                href="tel:+4915258492920"
                                target="_blank"
                                name="format-detection"
                                content="telephone=no"
                            >
                                {t('footer.phoneDetails')}
                            </Styled.ExternalLink>
                        </ParagraphWhite>
                        <ParagraphWhite>
                            <Styled.ExternalLink
                                href="mailto:info@medrefer.de"
                                target="_blank"
                            >
                                {' '}
                                {t('footer.emailDetails')}{' '}
                            </Styled.ExternalLink>
                        </ParagraphWhite>
                        <ParagraphWhite>
                            {t('footer.addressDetails')}
                        </ParagraphWhite>
                    </div>
                </Styled.LeftContent>
                <Styled.RightContent>
                    <LocaleLink to="/">
                        <Styled.Logo src={LogoSVG} alt="Medrefer logo" />
                    </LocaleLink>
                    <Styled.FooterMenu>
                        <div onClick={handleOnClick}>
                            <Styled.FooterMenuLink
                                to={''}
                                key={'cookieSettings'}
                            >
                                {t('footer.cookieSettings')}
                            </Styled.FooterMenuLink>
                        </div>

                        {menuItems(t).map((menuItem) => (
                            <Styled.FooterMenuLink
                                to={menuItem.link}
                                key={menuItem.name}
                            >
                                {menuItem.name}
                            </Styled.FooterMenuLink>
                        ))}
                        <span>
                            © {new Date().getFullYear()}
                            {t('footer.copyright')}
                        </span>
                    </Styled.FooterMenu>
                </Styled.RightContent>
            </Styled.FooterContent>
        </Styled.Footer>
    );
};
