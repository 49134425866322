import React from 'react';
import * as Styled from './MenuItem.styled';

interface MenuItemProps {
    to: string;
    text: string;
    handleClick?: () => void;
    isActive?: boolean;
}

export const MenuItem = ({ to, text, handleClick, isActive }: MenuItemProps) => (
    <Styled.NavLink isActive={isActive} to={to} onAnchorLinkClick={handleClick}>
        {text}
    </Styled.NavLink>
);
