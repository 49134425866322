import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import tw, { theme } from 'twin.macro';


export const LanguagePickerContainer = styled.div`
  ${tw``}
`;
export const LanguageItem = styled(GatsbyLink)`
${tw`text-3xl text-black font-medium`};
`
export const SelectLanguage = styled.div``

