import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const SelectLanguageWrapper = styled.div`
    ${tw`flex justify-center`}
    @media (min-width: ${theme`screens.md`}) {
        display: none;
    }
    a {
        &[aria-current='page'] {
            ${tw`underline`}
        }
    }
`;
