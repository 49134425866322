import React, { useState, useRef, useEffect } from 'react';
import * as Styled from './SelectLanguageDesktop.styled';
import { ArrowVertical } from './ArrowVertical';
import { LanguageItem } from '../LanguageItem';

export const SelectLanguageDesktop = ({
    lang,
    supportedLanguages,
    originalPath,
}) => {
    const [open, setOpen] = useState(false);
    const node = useRef(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClick = (e: { target: unknown }) => {
        if (node.current.contains(e.target)) {
            return;
        }
        if (open) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleClick]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <Styled.SelectLanguageWrapper ref={node} onClick={() => toggleOpen()}>
            <Styled.Button open={open}>
                <LanguageItem lang={lang} originalPath={originalPath} />
                <ArrowVertical />
            </Styled.Button>
            <Styled.LanguageTooltip open={open}>
                {supportedLanguages.map(
                    (supportedLang) =>
                        supportedLang !== lang && (
                            <LanguageItem
                                lang={supportedLang}
                                originalPath={originalPath}
                                key={supportedLang}
                            />
                        )
                )}
            </Styled.LanguageTooltip>
        </Styled.SelectLanguageWrapper>
    );
};
