import React from 'react';

export const ArrowVertical = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.666"
            height="7.613"
            viewBox="0 0 10.666 7.613"
        >
            <path
                id="Path_1"
                data-name="Path 1"
                d="M1611.368,59.25l4.044,4.651,3.8-4.651"
                transform="translate(-1609.957 -57.839)"
                fill="none"
                stroke="#2a2a2a"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </svg>
    );
};
