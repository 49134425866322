import { MenuItem } from '@/layouts/Layout.types';

export const menuItems = (t): MenuItem[] => {
    return [
        {
            name: t('footer.privacyPolicy'),
            link: '/privacy-policy',
        },
        // {
        //     name: t('footer.termsOfUse'),
        //     link: '/terms-of-use',
        // },
        // {
        //     name: t('footer.cookiesPolicy'),
        //     link: '/cookies-policy',
        // },
        {
            name: t('footer.imprint'),
            link: '/imprint',
        },
    ];
};
