// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-for-healthcare-providers-tsx": () => import("./../../../src/pages/for-healthcare-providers.tsx" /* webpackChunkName: "component---src-pages-for-healthcare-providers-tsx" */),
  "component---src-pages-for-hospitals-tsx": () => import("./../../../src/pages/for-hospitals.tsx" /* webpackChunkName: "component---src-pages-for-hospitals-tsx" */),
  "component---src-pages-for-technology-partners-tsx": () => import("./../../../src/pages/for-technology-partners.tsx" /* webpackChunkName: "component---src-pages-for-technology-partners-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-markdown-page-template-tsx": () => import("./../../../src/templates/markdownPageTemplate.tsx" /* webpackChunkName: "component---src-templates-markdown-page-template-tsx" */)
}

