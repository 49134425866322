import { createGlobalStyle } from 'styled-components';
import tw, { theme } from 'twin.macro';

export default createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
  
    body {
      ${tw`overflow-x-hidden`}; 
      font-family: Poppins;
      font-style: normal;
    }
    html, body {
        margin: 0;
        padding: 0;
    }
    html {
     overflow-y:scroll ;
    }
    #root {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    h1,h2,h3,h4,h5 {
      ${tw`font-display font-bold`};
    }
    p {
      ${tw`text-black text-sm`}
    }

    ul {
      ${tw`inline-flex`}
    }
    li {
      ${tw`mr-5`}
    }
    a {
      ${tw`text-black hover:underline hover:text-gray-500`}
    }

    .efre-logo-container {
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
    }
    .efre-logo-container > img {
      width: 400px;
      max-width: 100%;
    }

    .index-hero-section{
      ${tw`mb-24`}

      @media (max-width: ${theme`screens.lg`}) {
        ${tw`mb-24`}
      }
    }

    #solution-img {
      ${tw`mb-24 mx-auto`}
    }

    br{
      &.mobile{
        @media (min-width: ${theme`screens.lg`}) {
          display:none;
        }
      }

      &.desktop {
        @media (max-width: ${theme`screens.lg`}) {
          display:none;
        }
      }
    }
`;
