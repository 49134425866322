import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const MenuItemsContainer = styled.div<{ isOpen: boolean }>`
    ${tw`z-40`}
    background-color: ${theme`colors.white`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 350px;
    position: fixed;
    left: 0;
    top: 60px;
    transform-origin: 0% 0%;
    transform: ${({ isOpen }) =>
        isOpen ? 'translate(0, 0)' : 'translate(100%, 0)'};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    transition: all 0.3s ease;
    @media (min-width: ${theme`screens.md`}) {
        display: none;
    }
`;

export const LinksContainer = styled.div`
    ${tw`m-auto`}
    display: flex;
    flex-direction: column;
    a {
        margin: 10px 5px;
        text-align: center;
    }
`;
