import React from 'react';
import * as Styled from './SelectLanguageMobile.styled';
import { LanguageItem } from '@/components/LanguagePicker/LanguageItem';

export const SelectLanguageMobile = ({
    lang,
    supportedLanguages,
    originalPath,
}) => {
    return (
        <Styled.SelectLanguageWrapper>
            {supportedLanguages.map((supportedLang) => (
                <LanguageItem
                    lang={supportedLang}
                    originalPath={originalPath}
                    key={supportedLang}
                />
            ))}
        </Styled.SelectLanguageWrapper>
    );
};
