import React from 'react';
import * as Styled from './LanguageItem.styled';

export const LanguageItem = ({ lang, originalPath }) => (
    <Styled.LanguageItem
        aria-label={`Change language to ${lang}`}
        to={`/${lang}${originalPath}`}
        key={lang}
    >
        {lang.toUpperCase()}
    </Styled.LanguageItem>
);
