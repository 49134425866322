import React, { FC } from 'react';
import { MenuItemsProps } from '../MenuItems.types';
import * as Styled from './MenuItemsMobile.styled';
import { MenuItem } from '../MenuItem';
import { LanguagePicker } from '@/components/LanguagePicker';

interface MenuItemsMobileProps extends MenuItemsProps {
    isOpen: boolean;
    handleClick: () => void;
}

export const MenuItemsMobile: FC<MenuItemsMobileProps> = ({
    menuItems,
    isOpen,
    handleClick,
}) => {
    return (
        <Styled.MenuItemsContainer isOpen={isOpen}>
            <Styled.LinksContainer>
                {menuItems.map((menuItem) => (
                    <MenuItem
                        to={menuItem.link}
                        text={menuItem.name}
                        handleClick={handleClick}
                        key={menuItem.name}
                    />
                ))}
                <LanguagePicker />
            </Styled.LinksContainer>
        </Styled.MenuItemsContainer>
    );
};
