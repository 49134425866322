import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { usePageContext } from '@/contexts/PageContext';
export const LocaleLink = ({ isNewTab, to, ref, activeClassName, ...rest }) => {
    const { lang } = usePageContext();

    return isNewTab ? (
        <a href={`/${lang}${to}`} {...rest} target="_blank" />
    ) : (
        <AnchorLink {...rest} to={`/${lang}${to}`} />
    );
};


