import React, { useState } from 'react';
import { LocaleLink } from '@/components/LocaleLink';
import * as Styled from './Header.styled';
import LogoSVG from 'static/images/Logo.svg';
import { Hamburger } from './Hamburger';
import { MenuItemsDesktop } from './MenuItems/MenuItemsDesktop';
import { MenuItemsMobile } from './MenuItems/MenuItemsMobile';
import { menuItems } from './Header.helpers';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import i18n from '@/i18n/i18n';
import { useLocation } from '@reach/router';

interface Props {
    isStickyNav: boolean;
}

export const Header = ({ isStickyNav }: Props): JSX.Element => {
    const [menuOpen, setmMenuOpen] = useState(false);
    const { t } = useTranslation();
    const location = useLocation();

    const toggleMenu = () => {
        setmMenuOpen((prevState) => !prevState);
    };

    const closeMobileMenu = () => setmMenuOpen(false);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{
                opacity: 1,
                transition: { duration: 0.5, delay: 0.5, ease: 'easeOut' },
            }}
        >
            <Styled.Header isStickyNav={isStickyNav}>
                <Styled.NavbarContentContainer>
                    <Link to={`/${i18n.language}`}>
                        <Styled.Logo src={LogoSVG} alt="Medrefer logo" />
                    </Link>

                    <MenuItemsDesktop menuItems={menuItems(t, location)} />
                    <MenuItemsMobile
                        menuItems={menuItems(t, location)}
                        isOpen={menuOpen}
                        handleClick={closeMobileMenu}
                    />
                </Styled.NavbarContentContainer>

                <Hamburger menuOpen={menuOpen} toggleMenu={toggleMenu} />
            </Styled.Header>
        </motion.div>
    );
};
