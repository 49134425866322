import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ActiveNavItem = "home" | "problem" | "solution" | "aboutUs" | "contact"



const PageContext = React.createContext({
    activeNavItem: "home",
    setActiveNavItem: (item: ActiveNavItem) => { console.log(item) }
});

export const PageContextProvider = ({ pageContext, children }) => {
    const { i18n } = useTranslation();
    const [activeNavItem, setActiveNavItem] = useState<ActiveNavItem>("home");

    useEffect(() => {
        i18n.changeLanguage(pageContext.lang);
    }, [pageContext.lang, i18n]);

    return (
        <PageContext.Provider value={{
            activeNavItem,
            setActiveNavItem: (item: ActiveNavItem) => setActiveNavItem(item),
            ...pageContext
        }}>
            {children}
        </PageContext.Provider>
    );
};

export const usePageContext = () => React.useContext(PageContext);
