import React, { FC, useEffect } from 'react';
import { MenuItemsProps } from '../MenuItems.types';
import * as Styled from './MenuItemsDesktop.styled';
import { MenuItem } from '../MenuItem';
import { LanguagePicker } from '@/components/LanguagePicker';
import { usePageContext } from '@/contexts/PageContext';
import { useLocation } from '@reach/router';
export const MenuItemsDesktop: FC<MenuItemsProps> = ({ menuItems }) => {
    const { activeNavItem } = usePageContext();
    const location = useLocation();
    return (
        <Styled.MenuDesktopContainer>
            <Styled.MenuItemsContainer>
                {menuItems.map((menuItem) => {
                    let isActive =
                        menuItem.link
                            .toLowerCase()
                            .split(' ')
                            .join('')
                            .includes(activeNavItem.toLowerCase()) ||
                        menuItem.link === '/';
                    isActive = !location.pathname.includes(
                        'imprint' || 'privacy-policy'
                    )
                        ? isActive
                        : false;
                    return (
                        <MenuItem
                            isActive={isActive}
                            to={menuItem.link}
                            text={menuItem.name}
                            key={menuItem.name}
                        />
                    );
                })}
            </Styled.MenuItemsContainer>
            <LanguagePicker />
        </Styled.MenuDesktopContainer>
    );
};
