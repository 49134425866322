import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { usePageContext } from '@/contexts/PageContext';
import * as Styled from './LanguagePicker.styled';
import { SelectLanguageDesktop } from './SelectLanguageDesktop';
import { SelectLanguageMobile } from './SelectLanguageMobile';

export const LanguagePicker = () => {
    const { originalPath, lang } = usePageContext();
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        supportedLanguages
                    }
                }
            }
        `
    );

    return (
        <Styled.LanguagePickerContainer>
            <SelectLanguageMobile
                lang={lang}
                supportedLanguages={site.siteMetadata.supportedLanguages}
                originalPath={originalPath}
            />

            <SelectLanguageDesktop
                lang={lang}
                supportedLanguages={site.siteMetadata.supportedLanguages}
                originalPath={originalPath}
            />
        </Styled.LanguagePickerContainer>
    );
};
