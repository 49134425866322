import { useEffect, useState } from "react";

export function useStickyNav() {
    const [isStickyNav, setStickyNav] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const listenToScroll = () => {
        if (window.pageYOffset > 0) {
            setStickyNav(true);
            setIsScrolled(true)
        } else {
            setStickyNav(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => {
            window.removeEventListener("scroll", listenToScroll);
        };
    }, []);

    return { isStickyNav, isScrolled };
}

