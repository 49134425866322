import React from 'react';
import * as Styled from './Hamburger.styled';

interface HamburgerProps {
    menuOpen: boolean;
    toggleMenu: () => void;
}

export const Hamburger = ({ menuOpen, toggleMenu }: HamburgerProps) => (
    <Styled.MenuToggle open={menuOpen} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
    </Styled.MenuToggle>
);
