import styled from 'styled-components';
import tw, { theme } from 'twin.macro';
import { LocaleLink } from '@/components/LocaleLink';

export const Footer = styled.footer`
    ${tw`bg-primaryPurple`}
`;

export const FooterContent = styled.div`
    ${tw`max-w-screen-xl m-auto text-white flex flex-col md:flex-row justify-between`};
    padding: 30px 20px;
`;
export const Logo = styled.img`
    height: 32px;
    min-height: 32px;
`;

export const LeftContent = styled.div`
    ${tw`flex mx-auto mb-10 md:mx-0 md:mb-0`};
    max-width: 400px;
    p:not(:first-of-type) {
        margin-top: 10px;
    }
`;
export const RightContent = styled.div`
    ${tw`flex flex-col justify-between items-center md:items-end`};
`;

export const WhiteDot = styled.span`
    ${tw`block bg-white rounded-full mr-5`};
    width: 12px;
    height: 12px;
    margin-top: 4px;
`;

export const FooterMenu = styled.div`
    ${tw`flex flex-col mt-10 md:mt-0 md:flex-row items-center`};
`;

export const FooterMenuLink = styled(LocaleLink)`
    ${tw`text-white hover:text-white mb-5 md:mb-0 md:mr-10`};
`;


export const ExternalLink = styled.a<{ name?: string; content?: string }>`
    ${tw`text-white hover:text-white underline leading-loose`};
`;
