import styled from 'styled-components';
import tw, { theme } from 'twin.macro';

export const MainContainer = styled.div``;

export const ContentContainer = styled.main`
    ${tw`max-w-screen-xl m-auto`};
    padding: 0 100px;
    min-height: calc(100vh - 226px);

    @media (max-width: ${theme`screens.lg`}) {
        padding: 0 15px;
    }
`;
